.screen {
    height: 100vh;
    width: 100%;
    overflow: scroll;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

.projectContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100vh;
    scroll-snap-align: start;
}

@media screen and (max-width: 880px) {
    .screen {
        scroll-snap-points-y: repeat(800px);
        scroll-snap-type: none;
    }
    .projectContainer {
        flex-direction: column;
        height: max-content;
        height: auto;
        scroll-snap-align: none;
    }
}
