.mediaButton {
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    transition: .25s;
    background-color: #000000;
}

.mediaButton svg {
    font-size: 18px;
    color: #ffffff;
}

.mediaButton:hover {
    border-radius: 5px;
    background-color: rgba(39, 40, 41, 0.3);
}

.mediaButton:hover .mediaButton svg {
    color: black;
}
