.logo {
  height: 50px;
  margin-left: 48px;
}

.navList a {
  text-decoration: none;
}

.navBar {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
  color: #272829;
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: 1s;
}

.scrolled {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px -10px 20px 8px rgb(0 0 0 / 25%);
}

.navList {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
}

.navItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  text-align: center;
  font-size: 16px;
  height: 40px;
  margin-right: 10px;
  color: #000000;
  font-weight: bold;
  text-decoration: none;
  border-radius: 10px;
  transition: 0.5s;
  background-color: transparent;

  &:hover {
    background-color: #f5f5f5;
    border-radius: 50px;
  }
}

.active {
  background-color: #e0e0e0;
  border-radius: 50px;
}

.navigationLogo {
  height: 50px;
  width: 50px;
  border: 4px solid #000000;
  border-radius: 25px;
  margin-left: 48px;
}
