@font-face {
  font-family: 'OriScript';
  src: url('./fonts/TT-Commons-ExtraBold.woff') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Regular';
  src: url('./fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('./fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  src: url('./fonts/Outfit-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit Bold';
  src: url('./fonts/Outfit-Bold.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}

html, body, #root {
  margin: 0;
  min-height: 100vh;
}

body {
  margin: 0;
  color: #000000;
  font-family: 'Outfit', -apple-system, BlinkMacSystemFont, 'Open Sans Regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: white;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}


// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-family: 'OriScript';
//   letter-spacing: -1px;
// }

h1 {
  font-size: 50px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 30px;
  font-weight: lighter;
}

h4 {
  font-size: 30px;
  font-weight: lighter;
}

p {
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
