@import '../../scss/variables';

.navBar {
    width: 100%;
}

.roundedCorners {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
}

.navigationIconContainer {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    color: $primaryGray;
    font-size: 28px;
    right: 0;
    top: 0;
    padding: 20px;
    position: fixed;
    z-index: 3;
}

.navListContainer {
    display: flex;
    justify-content: center;
    background: transparent;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 2;
    top: 0px;
    padding-top: 70px;
    background: linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.navList {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    flex: 1;
    padding: 0;
    margin: 0;
    z-index: 3;
}

.navItem {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 110px;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    background-color: transparent;
    margin-right: 10px;
    margin-bottom: 10px;
    text-decoration: none;
    border-radius: 10px;
    transition: 0.5s;
}

.active {
    background-color: #000000;
    color: white;
    border-radius: 50px;
}

.navItem:hover {
    background-color: rgba(39, 40, 41, 0.3);
    border-radius: 50px;
}

.navList a {
    font-family: 'Nunito';
    text-decoration: none;
    color: $primaryGray;
    font-size: 18px;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 1
}

.none {
    display: none;
}
