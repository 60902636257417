.button {
    font-family: 'Nunito';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    color: black;
    text-decoration: none;
    border-radius: 20px;
    transition: 0.25s;
    background-color: transparent;
    background-color: black;
    color: #ffffff;
  }
  .button:hover {
    // box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
    color: #000000;
    background-color: rgb(0 0 0 / 25%);
  }
