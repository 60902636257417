.viewer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    position: fixed;
    overflow: scroll;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.8) 100%);
    z-index: 4;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.viewer button {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    border: none;
    color: #ffffff89;
    font-size: 36px;
    background-color: rgb(255, 255, 255, .1);
    transition: .25s;
}

.viewer button:hover {
    background-color: rgb(255, 255, 255, .2);
    border-radius: 10px;
    color: #ffffff;
}

.viewer video {
    max-width: 1000px;
    width: 100%;
    padding: 20px;
}
