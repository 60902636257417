.screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 800px;
    background-position: center center;
    background-size: cover;
    flex-direction: column;
}
