@mixin cardDimensions {
    width: 400px;
    height: 400px;
}

.projectsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 100px;
}

.card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: -20px 20px 30px 10px rgb(0 0 0 / 25%);
    border-radius: 5px;
    background-color: transparent;
    overflow: hidden;
}

.textContainer {
    // background-color: rgb(225, 225, 225);
    color: #000000;
    padding: 30px;
}

.title:visited,
.title::before {
    color: #000000;
}

.title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: #000000;
    border: none;
    background: transparent;
    cursor: pointer;
}

.title h2 {
    display: flex;
    align-items: center;
    text-align: left;
    font-family: 'Outfit Bold';
    margin: 20px 0px;
    letter-spacing: -1px;
}

.title svg {
    font-size: 14px;
    margin-left: 10px;
    color: rgb(165, 165, 165);
}

.bodyText {
    margin-top: 0px;
}

.infoText {
    font-size: 12px;
    color: rgb(175, 175, 175);
    margin: 0px;
}

.infoText svg {
    margin-right: 10px;
}

.mediaContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-color: rgb(225, 225, 225);
    padding: 40px
}

.mediaContainer h3 {
    font-family: 'Outfit Bold';
    font-size: 25px;
}

.media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.imageContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.iconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 25px;
    padding: 10px;
    margin-bottom: 30px;
    margin-top: 30px;
    width: max-content;
}

.iconContainer svg,
.iconContainer i {
    font-size: 20px;
    margin-right: 25px;
}

.notFoundImage {
    width: 60px;
}


@media screen and (max-width: 880px) {
    .card {
        overflow: visible;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
.cardItem {
    @include cardDimensions;
}

@media screen and (max-width: 500px) {
    .card {
        // box-shadow: none;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .cardItem {
        width: 320px;
        height: 320px
    }
    .iconContainer {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .title h2 {
        font-size: 24px;
    }
}
